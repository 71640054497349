import React from "react";
import "./Home.css";

// Components
import Seperator from "../../components/Seperator/Seperator";
import Introduction from "./Introduction/Introduction";
import Specialization from "./Specialization/Specialization";
import References from "./References/References";
import Explanation from "./Explanation/Explanation";
import HomeService from "./HomeService/HomeService";

export default function Home() {
  return (
    <div className="home nav-padding">
      <div className="section1 section ">
        <div className="section-content section-default-p">
          <Introduction />
        </div>
      </div>
      <div className="section2 section">
        <div className="section-content section-default-p">
          <Specialization />
        </div>
        <Seperator />
      </div>

      <div className="section3 section">
        <div className="section-content section-default-p">
          <References />
        </div>
        <Seperator />
      </div>

      <div className="section4 section">
        <div className="section-content section-default-p">
          <Explanation />
        </div>
      </div>

      <div className="section5 section">
        <div className="section-content ">
          <HomeService />
        </div>
        <Seperator />
      </div>
    </div>
  );
}
