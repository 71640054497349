import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// PRICES FOR COST_CALCULATOR_DATA //
import { calculatorValues } from "./constants/data";

// Images for EXPLANATION_DATA //
import customerImg from "./assets/images/customer.png";
import proposalImg from "./assets/images/proposal.png";
import designImg from "./assets/images/design.png";
import testingImg from "./assets/images/test.png";
import supportImg from "./assets/images/support.png";

// SVG //
import svg1 from "./assets/icons/icon-1.svg";
import svg2 from "./assets/icons/icon-2.svg";
import svg10 from "./assets/icons/icon-10.svg";
import svg11 from "./assets/icons/icon-11.svg";
import svg12 from "./assets/icons/icon-12.svg";
import svg13 from "./assets/icons/icon-13.svg";
import svg14 from "./assets/icons/icon-14.svg";
import svg15 from "./assets/icons/icon-15.svg";
import svg16 from "./assets/icons/icon-16.svg";
import svg3 from "./assets/icons/icon-3.svg";
import svg4 from "./assets/icons/icon-4.svg";
import svg5 from "./assets/icons/icon-5.svg";
import svg6 from "./assets/icons/icon-6.svg";
import svg7 from "./assets/icons/icon-7.svg";
import svg8 from "./assets/icons/icon-8.svg";
import svg9 from "./assets/icons/icon-9.svg";

i18n.use(initReactI18next).init({
  lng: "fi",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        NAV_TEXT: ["softstack", "services", "contact"],
        NAV_HOME: "softstack",
        NAV_SERVICES: "services",
        NAV_CONTACT: "contact",
        INTRO_HEADER: "is Lead Magnets For Wordpress Websites",
        INTRO_TEXT:
          "SoftStack is an innovative IT company founded by two young, energetic and permanent friends in Helsinki in 2018. We combine years of experience with expert IT projects. We strive to combine efficiency and quality in every project, and we always promise to handle tasks intelligently and professionally.",
        FREE_ASSESMENT: "FREE NEEDS ASSESMENT",
        PRICE_ESTIMATION: "Cost estimation",
        SPECIALIZATION_HEADER:
          "SoftStack specializes in developing digital solutions for small and medium-sized businesses.",
        SPECIALIZATION_PARAGRAPH:
          "We offer customized services that cover everything from responsive websites to custom web applications using the latest technologies and frameworks. Whether your need is a simple website or a complex, customized web application, we at SoftStack always focus on understanding the customer's wishes. We build responsive, visually appealing solutions that work seamlessly on all devices.",
        REFERENCE_HEADER: "Our References",
        REFERENCE_PARAGRAPH:
          "When we say our customers are happy, we mean it. Check out our references and see who we are proud to work with.",
        REFERENCE_OPEN: "Explore",
        EXPLANATION_DATA: {
          header: "How does it all work",
          paths: [
            {
              title: "Consulation",
              paragraph:
                "First, we discuss your needs and preferences to understand the project's scope ",
              img: customerImg,
            },
            {
              title: "Proposal",
              paragraph:
                "We will provdetailed proposal, outlining the project's timeline, deliverables, and cost.",
              img: proposalImg,
            },
            {
              title: "Design and Development",
              paragraph:
                "Our team gets to work on designing and developing your website or application.",
              img: designImg,
            },
            {
              title: "Testing and Launch",
              paragraph:
                "Before launching, we thoroughly test the project to ensure it's bug-free and ready for use.",
              img: testingImg,
            },
            {
              title: "Ongoing Support",
              paragraph:
                "We provgoing support and maintenance to ensure your digital solution remains up-to-date and effective.",
              img: supportImg,
            },
          ],
        },
        HOME_SERVICE_DATA: {
          header: "Discover all our services",
          services: [
            {
              title: "WordPress Website Development",
              content: [
                {
                  svg: svg16,
                  paragraph:
                    "WordPress is renowned for its flexibility, reliability, and ease of use.",
                },
                {
                  svg: svg1,
                  paragraph:
                    "Our team is skilled in creating WordPress websites that are not only visually appealing but also user-friendly and fully functional.",
                },
                {
                  svg: svg2,
                  paragraph:
                    "We will design a website that effectively communicates your brand message and engages your audience.",
                },
              ],
            },
            {
              title: "Domain Registration & Server Provision",
              content: [
                {
                  svg: svg13,
                  paragraph:
                    "Reliable hosting is essential for the smooth running of your website.",
                },
                {
                  svg: svg14,
                  paragraph:
                    "We offer robust WordPress hosting solutions to ensure your website is secure, loads quickly, and performs optimally.",
                },
                {
                  svg: svg15,
                  paragraph:
                    "Our hosting service includes regular updates and backups, along with 24/7 support to ensure your site is always up and running.",
                },
              ],
            },
            {
              title: "Website and Web Application Maintenance",
              content: [
                {
                  svg: svg10,
                  paragraph: "Need something more specialized?",
                },
                {
                  svg: svg11,
                  paragraph:
                    "We are adept at developing custom web applications using modern frameworks such as React.",
                },
                {
                  svg: svg12,
                  paragraph:
                    "Whether you require an interactive feature for your website or a full-blown web application, our team can design and develop a solution that fits your needs perfectly.",
                },
              ],
            },
          ],
        },
        SERVICE_PAGE_CARD_DATA: [
          {
            title: "WordPress Website Development",
            content: [
              {
                svg: svg16,
                paragraph:
                  "WordPress is renowned for its flexibility, reliability, and ease of use.",
              },
              {
                svg: svg1,
                paragraph:
                  "Our team is skilled in creating WordPress websites that are not only visually appealing but also user-friendly and fully functional.",
              },
              {
                svg: svg2,
                paragraph:
                  "We will design a website that effectively communicates your brand message and engages your audience.",
              },
            ],
          },
          {
            title: "WordPress Hosting",
            content: [
              {
                svg: svg13,
                paragraph:
                  "Reliable hosting is essential for the smooth running of your website.",
              },
              {
                svg: svg14,
                paragraph:
                  "We offer robust WordPress hosting solutions to ensure your website is secure, loads quickly, and performs optimally.",
              },
              {
                svg: svg15,
                paragraph:
                  "Our hosting service includes regular updates and backups, along with 24/7 support to ensure your site is always up and running.",
              },
            ],
          },
          {
            title: "Custom Web Applications",
            content: [
              {
                svg: svg10,
                paragraph: "Need something more specialized?",
              },
              {
                svg: svg11,
                paragraph:
                  "We are adept at developing custom web applications using modern frameworks such as React.",
              },
              {
                svg: svg12,
                paragraph:
                  "Whether you require an interactive feature for your website or a full-blown web application, our team can design and develop a solution that fits your needs perfectly.",
              },
            ],
          },
          {
            title: "Responsive Design",
            content: [
              {
                svg: svg3,
                paragraph:
                  "Starting a new business or setting up a new website can be daunting. ",
              },
              {
                svg: svg4,
                paragraph:
                  "We ensure our designs are fully responsive, prova seamless user experience whether your customers are on a desktop, laptop, tablet, or smartphone.",
              },
            ],
          },
          {
            title: "Domain Registration & Server Provision",
            content: [
              {
                svg: svg5,
                paragraph:
                  "Starting a new business or setting up a new website can be daunting. ",
              },
              {
                svg: svg6,
                paragraph:
                  "We take the hassle out of the process by offering domain registration and server provision services.",
              },
              {
                svg: svg7,
                paragraph:
                  "Let us handle the technicalities while you focus on growing your business.",
              },
            ],
          },
          {
            title: "Website and Web Application Maintenance",
            content: [
              {
                svg: svg8,
                paragraph:
                  "Our commitment doesn't end with the delivery of your website or application. ",
              },
              {
                svg: svg9,
                paragraph:
                  "We offer ongoing maintenance to ensure your digital solutions stay current, secure, and effective.",
              },
            ],
          },
        ],
        COST_CALCULATOR_DATA: {
          close_button: "close",
          header:
            "Create a price estimate for your new website using our convenient calculator!",
          header2: "We are committed to pricing clarity and transparency.",
          areas: {
            methods: {
              header: "Implementation Method",
              options: [
                {
                  id: 1,
                  selected: true,
                  text: "Producing pages from a ready-made design",
                  value: calculatorValues.produce.ready,
                },
                {
                  id: 2,
                  selected: false,
                  text: "Producing pages and designing the graphic layout",
                  value: calculatorValues.produce.design,
                },
              ],
            },
            pages: {
              header: "Number of Pages",
              options: [
                {
                  id: 1,
                  selected: true,
                  text: "1-2",
                  value: calculatorValues.pages.oneToTwo,
                },
                {
                  id: 2,
                  selected: false,
                  text: "2-4",
                  value: calculatorValues.pages.threeToFour,
                },
                {
                  id: 3,
                  selected: false,
                  text: "5-6",
                  value: calculatorValues.pages.fiveToSix,
                },
                {
                  id: 4,
                  selected: false,
                  text: "6+",
                  value: calculatorValues.pages.sixPlus,
                },
              ],
            },

            contents: {
              header: "Features",
              options: [
                {
                  id: 1,
                  selected: false,
                  text: "E-commerce",
                  value: calculatorValues.features.eCom,
                },
                {
                  id: 2,
                  selected: false,
                  text: "Integrations with other systems",
                  value: calculatorValues.features.integration,
                },
                {
                  id: 3,
                  selected: false,
                  text: "Content creation",
                  value: calculatorValues.features.contentCreation,
                },
              ],
            },
          },
          estimation: {
            header: "Price Estimate for the Pages",
            button: "Request a Quote",
          },
        },
        ADDED_TAX: "vat",
        CONTACT_HEADER:
          "We at SoftStack are committed to open communication and we believe in finding you. Whether you need it, you need a customized offer or you just want to know more about our services, we are happy to help.",
        FIRST_NAME: "First name",
        LAST_NAME: "Last name",
        EMAIL: "Email",
        ENTER_EMAIL: "Enter your email",
        SELECT_SUBJECT: "Select subject",
        CONTACT_OPTIONS: [
          "Offer request",
          "Maintenance services",
          "Feedback",
          "Error report",
        ],
        MESSAGE: "Message",
        WRITE_MESSAGE: "Write a message",
        SEND_MESSAGE: "Send a message",
        FOOTER_HEADER: "SOFTSTACK",
        FOOTER_PARAGRAPH: "IT solutions of the future, built today.",
        FOOTER_LINK_HEADER: "Useful links",
        FOOTER_CONTACT_HEADER: "Contact information",
        FOOTER_BUTTON: "CONTACT",
        FOOTER_CP: "All rights reserved by site. ",
        MESSAGE_SENT: "Message sent!",
        MESSAGE_FAILED: "Something went wrong, try again",
        FILL_MESSAGE:
          "Contact information and subject of contact must be filled in",
      },
    },
    fi: {
      translation: {
        NAV_TEXT: ["softstack", "palvelut", "yhteydenotto"],
        NAV_HOME: "softstack",
        NAV_SERVICES: "palvelut",
        NAV_CONTACT: "ota yhteyttä",
        INTRO_HEADER:
          "toteuttaa nettisivut ja muut IT-palvelut luotettavasti ja fiksusti.",
        INTRO_TEXT:
          "SoftStack on innovatiivinen IT-yritys, jonka kaksi nuorta, energistä ja pitkäaikaista ystävää perustivat Helsingissä vuonna 2018. Yhdistämme vuosien kokemuksen erilaisista IT-projekteista. Pyrimme yhdistämään tehokkuuden ja laadun jokaisessa projektissa, ja lupaamme aina hoitaa tehtävät älykkäästi ja ammattimaisesti.",
        FREE_ASSESMENT: "ILMAINEN TARPEIDEN ARVIOINTI",
        PRICE_ESTIMATION: "Hinta-arvio",
        SPECIALIZATION_HEADER:
          "SoftStack on erikoistunut digitaalisten ratkaisujen kehittämiseen pienille ja keskisuurille yrityksille.",
        SPECIALIZATION_PARAGRAPH:
          "Tarjoamme räätälöityjä palveluita, jotka kattavat kaiken responsiivisista verkkosivustoista uusimpia tekniikoita ja kehyksiä käyttäviin räätälöityihin verkkosovelluksiin. Olipa tarpeesi yksinkertainen verkkosivusto tai monimutkainen, räätälöity verkkosovellus, me SoftStackissa keskitymme aina ymmärtämään asiakkaan toiveita. Rakennamme responsiivisia, visuaalisesti houkuttelevia ratkaisuja, jotka toimivat saumattomasti kaikilla laitteilla.",
        REFERENCE_HEADER: "Referenssit",
        REFERENCE_PARAGRAPH:
          "Kun sanomme, että asiakkaamme ovat tyytyväisiä, tarkoitamme sitä. Tutustu referensseihimme ja näe, kenen kanssa olemme ylpeitä tekevämme yhteistyötä.",
        REFERENCE_OPEN: "Tutustu",
        EXPLANATION_DATA: {
          header: "Miten se kaikki toimii",
          paths: [
            {
              title: "Konsulaatio",
              paragraph:
                "Ensin keskustelemme tarpeistasi ja mieltymyksistäsi ymmärtääksemme projektin laajuuden",
              img: customerImg,
            },
            {
              title: "Ehdotus",
              paragraph:
                "Esitämme yksityiskohtaisen ehdotuksen, jossa hahmotellaan projektin aikataulu, suoritukset ja kustannukset.",
              img: proposalImg,
            },
            {
              title: "Suunnittelu ja kehitys",
              paragraph:
                "Tiimimme saa työskennellä verkkosivustosi tai sovelluksesi suunnittelun ja kehittämisen parissa",
              img: designImg,
            },
            {
              title: "Testaus ja käynnistäminen",
              paragraph:
                "Ennen käynnistämistä testaamme projektin perusteellisesti varmistaaksemme, että se on virheetön ja käyttövalmis.",
              img: testingImg,
            },
            {
              title: "Jatkuva tuki",
              paragraph:
                "Jatkamme tukea ja ylläpitoa varmistaaksemme, että digitaalinen ratkaisusi pysyy ajan tasalla ja tehokkaana.",
              img: supportImg,
            },
          ],
        },
        HOME_SERVICE_DATA: {
          header: "Tutustu kaikkiin palveluihimme",
          services: [
            {
              title: "WordPress-verkkosivustojen kehittäminen",
              content: [
                {
                  svg: svg16,
                  paragraph:
                    "WordPress on tunnettu joustavuudestaan, luotettavuudestaan ja helppokäyttöisyydestään.",
                },
                {
                  svg: svg1,
                  paragraph:
                    "Tiimimme on taitava luomaan WordPress-verkkosivustoja, jotka eivät ole vain visuaalisesti houkuttelevia, vaan myös käyttäjäystävällisiä ja täysin toiminnallisia.",
                },
                {
                  svg: svg2,
                  paragraph:
                    "Suunnittelemme verkkosivuston, joka tehokkaasti välittää brändiviestisi ja sitouttaa yleisösi.",
                },
              ],
            },
            {
              title: "Verkkotunnuksen rekisteröinti ja palvelimen tarjoaminen",
              content: [
                {
                  svg: svg13,
                  paragraph:
                    "Luotettava hostaus on olennainen osa verkkosivustosi sujuvaa toimintaa.",
                },
                {
                  svg: svg14,
                  paragraph:
                    "Tarjoamme vankkoja WordPress-hosting-ratkaisuja varmistaaksemme, että verkkosivustosi on turvallinen, latautuu nopeasti ja toimii optimaalisesti.",
                },
                {
                  svg: svg15,
                  paragraph:
                    "Hosting-palveluumme sisältyvät säännölliset päivitykset ja varmuuskopiot sekä ympärivuorokautinen tuki, jotta sivustosi on aina käytettävissä.",
                },
              ],
            },
            {
              title: "Verkkosivuston ja web-sovelluksen ylläpito",
              content: [
                {
                  svg: svg10,
                  paragraph: "Tarvitsetko jotain erityistä?",
                },
                {
                  svg: svg11,
                  paragraph:
                    "Olemme taitavia kehittämään räätälöityjä web-sovelluksia käyttäen moderneja kehykkeitä, kuten React.",
                },
                {
                  svg: svg12,
                  paragraph:
                    "Olipa kyse interaktiivisesta ominaisuudesta verkkosivustollesi tai täysimittaisesta web-sovelluksesta, tiimimme voi suunnitella ja kehittää ratkaisun, joka sopii täydellisesti tarpeisiisi.",
                },
              ],
            },
          ],
        },
        SERVICE_PAGE_CARD_DATA: [
          {
            title: "WordPress-verkkosivustojen kehittäminen",
            content: [
              {
                svg: svg16,
                paragraph:
                  "WordPress on tunnettu joustavuudestaan, luotettavuudestaan ja helppokäyttöisyydestään.",
              },
              {
                svg: svg1,
                paragraph:
                  "Tiimimme on taitava luomaan WordPress-verkkosivustoja, jotka eivät ole vain visuaalisesti houkuttelevia, vaan myös käyttäjäystävällisiä ja täysin toiminnallisia.",
              },
              {
                svg: svg2,
                paragraph:
                  "Suunnittelemme verkkosivuston, joka tehokkaasti välittää brändiviestisi ja sitouttaa yleisösi.",
              },
            ],
          },
          {
            title: "WordPress-hostaus",
            content: [
              {
                svg: svg13,
                paragraph:
                  "Luotettava hostaus on olennainen osa verkkosivustosi sujuvaa toimintaa.",
              },
              {
                svg: svg14,
                paragraph:
                  "Tarjoamme vankkoja WordPress-hoostaus-ratkaisuja varmistaaksemme, että verkkosivustosi on turvallinen, latautuu nopeasti ja toimii optimaalisesti.",
              },
              {
                svg: svg15,
                paragraph:
                  "Hostaus-palveluumme sisältyvät säännölliset päivitykset ja varmuuskopiot sekä ympärivuorokautinen tuki, jotta sivustosi on aina käytettävissä.",
              },
            ],
          },
          {
            title: "Räätälöidyt web-sovellukset",
            content: [
              {
                svg: svg10,
                paragraph: "Tarvitsetko jotain erityistä?",
              },
              {
                svg: svg11,
                paragraph:
                  "Olemme taitavia kehittämään räätälöityjä web-sovelluksia käyttäen moderneja kehykkeitä, kuten React.",
              },
              {
                svg: svg12,
                paragraph:
                  "Olipa kyse interaktiivisesta ominaisuudesta verkkosivustollesi tai täysimittaisesta web-sovelluksesta, tiimimme voi suunnitella ja kehittää ratkaisun, joka sopii täydellisesti tarpeisiisi.",
              },
            ],
          },
          {
            title: "Responsiivinen suunnittelu",
            content: [
              {
                svg: svg3,
                paragraph:
                  "Uuden yrityksen perustaminen tai uuden verkkosivuston luominen voi tuntua pelottavalta.",
              },
              {
                svg: svg4,
                paragraph:
                  "Varmistamme, että suunnittelumme on täysin responsiivista, tarjoten saumattoman käyttökokemuksen asiakkaillesi, olivatpa he sitten työpöydällä, kannettavalla tietokoneella, tabletilla tai älypuhelimella.",
              },
            ],
          },
          {
            title: "Verkkotunnuksen rekisteröinti ja palvelimen tarjoaminen",
            content: [
              {
                svg: svg5,
                paragraph:
                  "Uuden yrityksen perustaminen tai uuden verkkosivuston luominen voi tuntua pelottavalta.",
              },
              {
                svg: svg6,
                paragraph:
                  "Helpotamme prosessia tarjoamalla verkkotunnuksen rekisteröintiä ja palvelimen tarjoamisen palveluita.",
              },
              {
                svg: svg7,
                paragraph:
                  "Anna meidän hoitaa tekniset yksityiskohdat, kun sinä keskityt liiketoimintasi kasvattamiseen.",
              },
            ],
          },
          {
            title: "Verkkosivuston ja web-sovelluksen ylläpito",
            content: [
              {
                svg: svg8,
                paragraph:
                  "Sitoumuksemme ei pääty verkkosivustosi tai sovelluksesi toimitukseen.",
              },
              {
                svg: svg9,
                paragraph:
                  "Tarjoamme jatkuvaa ylläpitoa, jotta digitaaliset ratkaisusi pysyvät ajan tasalla, turvallisina ja tehokkaina.",
              },
            ],
          },
        ],
        COST_CALCULATOR_DATA: {
          close_button: "sulje",
          header:
            "Luo hinta-arvio uusille verkkosivuillesi käyttämällä kätevää laskuriamme!",
          header2:
            "Olemme sitoutuneet hinnoittelun selkeyyteen ja avoimuuteen.",
          areas: {
            methods: {
              header: "Toteutustapa",
              options: [
                {
                  id: 1,
                  selected: true,
                  text: "Sivujen tuottaminen valmiista designista",
                  value: calculatorValues.produce.ready,
                },
                {
                  id: 2,
                  selected: false,
                  text: "Sivujen tuottaminen ja graaffisen ilmeen suunnittelu",
                  value: calculatorValues.produce.design,
                },
              ],
            },
            pages: {
              header: "Sivujen määrä",
              options: [
                {
                  id: 1,
                  selected: true,
                  text: "1-2",
                  value: calculatorValues.pages.oneToTwo,
                },
                {
                  id: 2,
                  selected: false,
                  text: "2-4",
                  value: calculatorValues.pages.threeToFour,
                },
                {
                  id: 3,
                  selected: false,
                  text: "5-6",
                  value: calculatorValues.pages.fiveToSix,
                },
                {
                  id: 4,
                  selected: false,
                  text: "6+",
                  value: calculatorValues.pages.sixPlus,
                },
              ],
            },

            contents: {
              header: "Ominaisuudet",
              options: [
                {
                  id: 1,
                  selected: false,
                  text: "Verkkokauppa",
                  value: calculatorValues.features.eCom,
                },
                {
                  id: 2,
                  selected: false,
                  text: "Integraatiot muihin järjestelmiin",
                  value: calculatorValues.features.integration,
                },
                {
                  id: 3,
                  selected: false,
                  text: "Tekstisällön tuotto",
                  value: calculatorValues.features.contentCreation,
                },
              ],
            },
          },
          estimation: {
            header: "Hinta-arvio sivuille",
            button: "Pyydä tarjous",
          },
        },
        ADDED_TAX: "alv",
        CONTACT_HEADER:
          "Me SoftStackilla uskomme avoimeen viestintään ja haluaisimme kuulla sinusta. Olipa sinulla kysyttävää, tarvitset mukautetun tarjouksen tai haluat vain tietää lisää palveluistamme, autamme mielellämme.",
        FIRST_NAME: "Etunimi",
        LAST_NAME: "Sukunimi",
        EMAIL: "Sähköposti",
        ENTER_EMAIL: "Syötä sähköpostiosoite",
        SELECT_SUBJECT: "Valitse aihe",
        CONTACT_OPTIONS: [
          "Tarjouspyyntö",
          "Ylläpitopalvelut",
          "Vikailmoitus",
          "Asiakaspalaute",
        ],
        MESSAGE: "Viesti",
        WRITE_MESSAGE: "Kirjoita viesti",
        SEND_MESSAGE: "Lähetä viesti",
        FOOTER_HEADER: "SOFTSTACK",
        FOOTER_PARAGRAPH: "Tulevaisuuden IT-ratkaisut, rakennettu tänään.",
        FOOTER_LINK_HEADER: "Hyödylliset linkit",
        FOOTER_CONTACT_HEADER: "Yhteystiedot",
        FOOTER_BUTTON: "OTA YHTEYTTÄ",
        FOOTER_CP: "Kaikki oikeudet pidätetään sivustolla.",
        MESSAGE_SENT: "Viesti lähetetty!",
        FILL_MESSAGE:
          "Yhteystiedot, sekä yhteydenoton aihe tulee olla täytettynä",
        MESSAGE_FAILED: "Jotain meni pieleen, yritäthän uudelleen.",
      },
    },
  },
});

export default i18n;
