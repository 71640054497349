import React from "react";

import "./Seperator.css";

export default function Seperator() {
  return (
    <div className="separator">
      <svg
        className="separator__svg"
        width="100%"
        style={{ height: "var(--seperator-height" }}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M 100 100 V 10 L 0 100" />
      </svg>
    </div>
  );
}
