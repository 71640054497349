import React from "react";
import "./ServiceCard.css";

export default function ServiceCard({ data, optBackground }) {
  return (
    <div
      className={
        !optBackground ? "service-card flex" : "service-card flex sc-opt-bg"
      }
    >
      <div className="service-card-title">{data.title}</div>
      <div className="service-card-content">
        {data.content.map((content, index) => {
          return (
            <div key={index} className="service-card-content-section">
              <div className="svg-container">
                <img alt={data.title} src={content.svg} />
              </div>
              <p>{content.paragraph} </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
