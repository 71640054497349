import React from "react";
import { useEffect } from "react";
import "./LangSwitcher.css";

// Translation //
import { useTranslation } from "react-i18next";

// Context //
import { useTranslationUpdateContext } from "../../../Context/TranslationContext";

export default function LangSwitcher() {
  const switchTranslation = useTranslationUpdateContext();
  const { i18n } = useTranslation();
  let language = i18n.language;

  useEffect(() => {
    let langButtons = document.getElementsByClassName("lang-p");
    for (let i = 0; i < langButtons.length; i++) {
      if (langButtons[i].id === language) {
        langButtons[i].classList.add("lang-selected");
      } else {
        langButtons[i].classList.remove("lang-selected");
      }
    }
  }, [language]);

  return (
    <div className="lang-switcher">
      <p id="fi" className="lang-p" onClick={() => switchTranslation("fi")}>
        fi
      </p>
      <p id="en" className="lang-p" onClick={() => switchTranslation("en")}>
        en
      </p>
    </div>
  );
}
