import React from "react";

import "./Contact.css";

// Components
import Seperator from "../../components/Seperator/Seperator";
import ContactBar from "./ContactBar/ContactBar";
import Map from "./Map/Map";
import ContactForm from "./ContactForm/ContactForm";

// Context
import { useTranslationContext } from "../../Context/TranslationContext";

export default function Contact() {
  const translation = useTranslationContext();
  return (
    <div className="contact nav-padding">
      <div className="contact-title">
        <h1>{translation("NAV_CONTACT")}</h1>
      </div>
      <div className="contact-page-container">
        <div className="contact-page-inner-container">
          <h2 className="contact-p">{translation("CONTACT_HEADER")}</h2>
          <ContactBar />
          <div className="flex media-1100-flex-c contact-container">
            <ContactForm />
            <Map />
          </div>
        </div>
        <Seperator />
      </div>
    </div>
  );
}
