import React from "react";
import "./ContactBar.css";

// MUI ICONS //
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";

// Data //
import { contactData } from "../../../constants/data";

export default function ContactBar() {
  return (
    <div className="contact-bar">
      <div className="contact-bar-info">
        <EmailIcon />
        <p>{contactData.email}</p>
      </div>
      <div className="contact-bar-info">
        <ContactPhoneIcon />
        <p>{contactData.phone}</p>
      </div>
      <div className="contact-bar-info">
        <PlaceIcon />
        <p>{contactData.location}</p>
      </div>
    </div>
  );
}
