// THIS FILE ONLY CONTAINS DATA WHICH WILL NOT BE TRANSLATED TO OTHER LANGUAGES; REST OF THE DATA CAN BE FOUND IN src/i18n.js//

// Reference images //
import fiberit from "../assets/images/references/Fiberit.png";
import kotikiinteistöt from "../assets/images/references/Kotikiinteistöt.png";
import kotipäivystys from "../assets/images/references/Kotipäivystys.png";
import kotitalkkarit from "../assets/images/references/Kotitalkkarit.png";
import hankintaKumppanit from "../assets/images/references/HankintaKumppanit.png";

export const referenceData = {
  header: "Our references",
  references: [
    {
      text: "",
      img: fiberit,
      href: "https://fiberit.fi/",
    },
    {
      text: "",
      img: kotikiinteistöt,
      href: "https://www.xn--kotikiinteistt-7pb.fi/",
    },
    {
      text: "",
      img: kotipäivystys,
      href: "https://www.kotipaivystys.fi/",
    },
    {
      text: "",
      img: kotitalkkarit,
      href: "https://helsinginkotitalkkarit.fi/",
    },
    {
      text: "",
      img: hankintaKumppanit,
      href: "https://hankintakumppanit.fi/",
    },
  ],
};

/* CONTACT DATA --- */
export const contactData = {
  email: "info@sofstack.com",
  supportEmail: "support@softstack.com",
  phone: "+358 40 3702622",
  location: "Helsinki, Finland",
};

// PATHS TO DIFFERENT PAGES, used in files with navPaths[index], rendered texts can be found in src/i18n.js
export const navPaths = ["softstack", "services", "contact"];

/* COST CALCULATOR DATA */
export const calculatorValues = {
  produce: {
    ready: 1000,
    design: 1800,
  },
  pages: {
    oneToTwo: 500,
    threeToFour: 800,
    fiveToSix: 1100,
    sixPlus: 1300,
  },
  features: {
    eCom: 1000,
    integration: 500,
    contentCreation: 300,
  },
};

// API //
export const URL_API_EMAIL = "https://ss-email-five.vercel.app/api/email";
