import axios from "axios";
import { URL_API_EMAIL } from "../constants/data";

export async function apiSendMail(contact) {
  try {
    const response = await axios.post(URL_API_EMAIL, {
      sender: contact.first_name + " " + contact.last_name,
      content: contact.message,
      subject: contact.subject,
      email: contact.email,
    });
    return response;
  } catch (e) {
    return { data: "not_ok" };
  }
}
