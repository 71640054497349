import React from "react";
import { useContext, useState } from "react";

const CalculatorContext = React.createContext();
const CalculatorUpdateContext = React.createContext();

export function useCalculator() {
  return useContext(CalculatorContext);
}

export function useCalculatorUpdate() {
  return useContext(CalculatorUpdateContext);
}

export function CalculatorProvider({ children }) {
  const [calculatorIsOpen, setCalculatorIsOpen] = useState(false);

  function switchCalculatorState() {
    setCalculatorIsOpen((prev) => !prev);
  }

  return (
    <CalculatorContext.Provider value={calculatorIsOpen}>
      <CalculatorUpdateContext.Provider value={switchCalculatorState}>
        {children}
      </CalculatorUpdateContext.Provider>
    </CalculatorContext.Provider>
  );
}
