import React from "react";
import "./Introduction.css";

// Components //
import CustomButton from "../../../components/CustomButton/CustomButton";

// Context //
import { useCalculatorUpdate } from "../../../Context/CalculatorContext";
import { useTranslationContext } from "../../../Context/TranslationContext";

// Data //
import introImage from "../../../assets/images/_lowQuality_Introduction.png";

export default function Introduction(props) {
  const openCalculator = useCalculatorUpdate();
  const translation = useTranslationContext();

  return (
    <div className="introduction flex media-1100-flex-cr">
      <div className="image-container">
        <img src={introImage} alt="img" />
      </div>
      <div className="introduction-paragraph">
        <h1>
          <span>Softstack</span> {translation("INTRO_HEADER")}
        </h1>
        <p>{translation("INTRO_TEXT")}</p>
        <CustomButton
          text={translation("PRICE_ESTIMATION")}
          onClick={() => openCalculator()}
          custWidth={"250px"}
        />
      </div>
    </div>
  );
}
