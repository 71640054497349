import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

// Context //
const TranslatorContext = React.createContext();
const UpdateTranslatorContext = React.createContext();

export function useTranslationContext() {
  return useContext(TranslatorContext);
}

export function useTranslationUpdateContext() {
  return useContext(UpdateTranslatorContext);
}

export function TranslationProvider({ children }) {
  const { i18n, t } = useTranslation();

  function switchTranslation(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <TranslatorContext.Provider value={t}>
      <UpdateTranslatorContext.Provider value={switchTranslation}>
        {children}
      </UpdateTranslatorContext.Provider>
    </TranslatorContext.Provider>
  );
}
