import React from "react";

import "./Specialization.css";

// Context //
import { useTranslationContext } from "../../../Context/TranslationContext";

// Data //
import specializationImage from "../../../assets/images/_lowQuality_Specialization.png";

export default function Specialization() {
  const translation = useTranslationContext();

  return (
    <div className="specialization flex media-1100-flex-c">
      <div className="specizalization-paragraph">
        <h2>{translation("SPECIALIZATION_HEADER")}</h2>
        <p>{translation("SPECIALIZATION_PARAGRAPH")}</p>
      </div>
      <div className="specialization-image-container">
        <img src={specializationImage} alt="computer" />
      </div>
    </div>
  );
}
