import { useEffect } from "react";

export default function useHideScrollBar(condition) {
  useEffect(() => {
    if (condition) {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    }
  }, [condition]);
}
