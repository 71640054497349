import React from "react";

import "./References.css";

// Components //
import Slider from "./Slider/Slider";

// Context //
import { useTranslationContext } from "../../../Context/TranslationContext";

export default function References() {
  const translation = useTranslationContext();
  return (
    <div className="references flex media-1100-flex-c">
      <div className="references-paragraph">
        <h2>{translation("REFERENCE_HEADER")}</h2>
        <p>{translation("REFERENCE_PARAGRAPH")}</p>
      </div>
      <Slider />
    </div>
  );
}
