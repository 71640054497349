import { React, useState } from "react";

import "./Slider.css";

// Data //
import { referenceData } from "../../../../constants/data";

// Left & Right Arrow Icons //
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

// Context //
import { useTranslationContext } from "../../../../Context/TranslationContext";

// Utils //
import { openInNewTab } from "../../../../utils/browser";

export default function Slider() {
  const translation = useTranslationContext();

  const [selectedSlide, setSelectedSlide] = useState(0);

  const referencesLength = referenceData.references.length;

  const switchPreviousSlide = () => {
    if (selectedSlide === 0) {
      return setSelectedSlide(referencesLength - 1);
    }
    return setSelectedSlide((prev) => prev - 1);
  };

  const switchNextSlide = () => {
    if (selectedSlide + 1 === referencesLength) {
      return setSelectedSlide(0);
    }
    return setSelectedSlide((prev) => prev + 1);
  };

  return (
    <div className="slider">
      <div className="slider-image-wrapper">
        <div
          className="slider-image-link"
          href="asd"
          onClick={() =>
            openInNewTab(referenceData.references[selectedSlide].href)
          }
        >
          <div className="slider-image-hover-text">
            <p>{translation("REFERENCE_OPEN")}</p>
          </div>

          <img
            src={referenceData.references[selectedSlide].img}
            alt="reference"
          />
        </div>
      </div>
      <div className="slider-indicator-container flex">
        <div
          id="slider-prev"
          className="slider-button"
          onClick={() => {
            switchPreviousSlide();
          }}
        >
          <WestIcon />
        </div>
        {referenceData.references.map((ref, index) => {
          return (
            <div
              className="slider-indicator"
              key={index}
              style={{
                backgroundColor:
                  selectedSlide === index ? "var(--secondary-color)" : null,
              }}
            />
          );
        })}
        <div
          id="slider-next"
          className="slider-button"
          onClick={() => {
            switchNextSlide();
          }}
        >
          <EastIcon />
        </div>
      </div>
    </div>
  );
}
