import React from "react";
import "./Explanation.css";

// Components //
import WorkPath from "./WorkPath/WorkPath";

// Context //
import { useTranslationContext } from "../../../Context/TranslationContext";
import pathImage from "../../../assets/images/_lowQuality_Path.png";

export default function Explanation() {
  const translation = useTranslationContext();
  const explanationData = translation("EXPLANATION_DATA", {
    returnObjects: true,
  });

  return (
    <div className="explanation flex media-1100-flex-cr">
      <div className="explanation-image-container">
        <img src={pathImage} alt="computer" />
      </div>
      <div className="explanation-paragraph">
        <h2>{explanationData.header}</h2>
        {explanationData.paths.map((path, index) => {
          return (
            <WorkPath
              key={index}
              title={path.title}
              paragraph={path.paragraph}
              img={path.img}
            />
          );
        })}
      </div>
    </div>
  );
}
