import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Components //
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import CostCalculator from "./components/CostCalculator/CostCalculator";

// Views //
import Services from "./views/page_service/Services";
import Home from "./views/page_home/Home";
import Contact from "./views/page_contact/Contact";

// Hooks
import ScrollToTop from "./Hooks/ScrollToTop";

// Providers
import { CalculatorProvider } from "./Context/CalculatorContext";
import { TranslationProvider } from "./Context/TranslationContext";

// DATA
import { navPaths } from "./constants/data";

function App() {
  return (
    <>
      <BrowserRouter>
        <TranslationProvider>
          <CalculatorProvider>
            <ScrollToTop />
            <NavBar />
            <Routes>
              <Route exact path={"/*"} element={<Home />} />;
              <Route exact path={"/" + navPaths[0]} element={<Home />} />
              <Route exact path={"/" + navPaths[1]} element={<Services />} />
              <Route exact path={"/" + navPaths[2]} element={<Contact />} />
            </Routes>
            <Footer />
            <CostCalculator />
          </CalculatorProvider>
        </TranslationProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
