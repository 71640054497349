import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Do NOT remove this import, even though it seems unused //
import i18n from "../../i18n";

// Components //
import CustomButton from "../CustomButton/CustomButton";
import { navPaths } from "../../constants/data";

// Hooks
import useHideScrollBar from "../../Hooks/useHideScrollBar";

// Context
import { useTranslationContext } from "../../Context/TranslationContext";

// Lang
import LangSwitcher from "./LangSwitcher/LangSwitcher";

// Style
import "./NavBar.css";
import logo from "../../assets/images/logo.png";

export default function NavBar() {
  const MOBILE_WIDTH = 800;

  const translation = useTranslationContext();
  const navText = translation("NAV_TEXT", { returnObjects: true });

  const navigate = useNavigate();
  const location = useLocation();

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  // If window width > mobile width, close nav
  const unExpandNav = () => {
    if (window.innerWidth > MOBILE_WIDTH) {
      setIsNavExpanded(false);
      document.getElementById("nav").style.left = `-${MOBILE_WIDTH + 25}px`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", unExpandNav);
  });

  function openNav() {
    document.getElementById("nav").style.left = "0px";
  }
  function closeNav() {
    document.getElementById("nav").style.left = `-${MOBILE_WIDTH + 25}px`;
  }

  const handleNav = () => {
    setIsNavExpanded(!isNavExpanded);
    if (isNavExpanded) {
      return closeNav();
    }
    return openNav();
  };

  // Disables scrolling if nav is open //
  useHideScrollBar(isNavExpanded);

  /* Function to navigate to path */
  const navigateTo = (path) => {
    navigate(path);
    document.getElementById("nav").style.left = "-825px";
    setIsNavExpanded(false);
  };

  /* Change style for current navPaths navLink */
  useEffect(() => {
    let pathName = location.pathname.substring(1);
    if (!navPaths.includes(pathName)) {
      pathName = navPaths[0];
    }

    const navbarLinks = document.querySelectorAll(".navbar-links li");
    navbarLinks.forEach((link) => {
      link.classList.remove("navbar-selected");
    });
    document.querySelector(`#nav-${pathName}`).classList.add("navbar-selected");
  }, [location.pathname]);

  return (
    <div className="navbar">
      <div className="navbar-items">
        <img
          alt="softstack"
          className="navbar-logo"
          src={logo}
          onClick={() => {
            navigate("/" + navPaths[0]);
          }}
        />
        <ul id="nav" className="navbar-links">
          {navPaths.map((path, index) => {
            return (
              <li
                className="navbar-link"
                key={path}
                id={`nav-${path}`}
                onClick={() => navigateTo(path)}
              >
                {navText[index]} {/* */}
              </li>
            );
          })}
        </ul>
        <div className="contact-lang-container">
          <CustomButton
            text={translation("NAV_CONTACT")}
            onClick={() => navigate("/" + navPaths[2])}
            custWidth={"150px"}
          />
          <LangSwitcher />
        </div>
        {/* MOBILE NAV BUTTON*/}
        <div
          className={
            isNavExpanded ? "nav-expand nav-expand-expanded" : "nav-expand"
          }
          onClick={() => {
            handleNav();
          }}
        >
          <div id="line1" className="nav-expand-line" />
          <div id="line2" className="nav-expand-line" />
          <div id="line3" className="nav-expand-line" />
        </div>
      </div>
    </div>
  );
}
