import React from "react";

import "./WorkPath.css";

export default function WorkPath({ img, title, paragraph }) {
  return (
    <div className="work-path flex">
      <ul className="work-path-content">
        <li>
          <div className="work-path-content-image-container">
            <img alt={title} src={img}></img>
          </div>
          <p>
            <span>{title + ": "}</span>
            {paragraph}
          </p>
        </li>
      </ul>
    </div>
  );
}
