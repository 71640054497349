import React from "react";

import "./CustomInput.css";

export default function CustomInput({
  fieldName,
  placeholder,
  textarea,
  select,
  options,
  onChange,
  value,
}) {
  if (textarea)
    return (
      <div className="custom-input" fieldname={fieldName}>
        <textarea
          value={value}
          placeholder={placeholder}
          cols="40"
          rows="5"
          onChange={(e) => onChange(e)}
        ></textarea>
      </div>
    );

  if (select)
    return (
      <div className="custom-input" fieldname={fieldName}>
        <select name="subject" id="subject" onChange={(e) => onChange(e)}>
          {options.map((option, index) => {
            return (
              <option value={option} key={index}>
                {option}
              </option>
            );
          })}
        </select>
      </div>
    );

  return (
    <div className="custom-input" fieldname={fieldName}>
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
}
