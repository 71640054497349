import React from "react";

import "./CustomButton.css";

export default function CustomButton({ text, onClick, custWidth, loading }) {
  return (
    <button
      className="custom-button"
      onClick={onClick}
      style={custWidth ? { width: custWidth } : { width: null }}
      disabled={loading ? true : false}
    >
      {!loading ? text : <div className="custom-button-loading"></div>}
    </button>
  );
}
