const date = new Date();

export const currentYear = () => {
  return date.getFullYear();
};

export const currentDateToString = () => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day}.${month}.${year}`;
};
