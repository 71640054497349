import React from "react";
import { useState } from "react";
import "./ContactForm.css";

// Components
import CustomInput from "./CustomInput";
import CustomButton from "../../../components/CustomButton/CustomButton";

// Api
import { apiSendMail } from "../../../api/apiSendMail";

// Context
import { useTranslationContext } from "../../../Context/TranslationContext";

export default function ContactForm() {
  const translation = useTranslationContext();
  const [loading, setLoading] = useState(false);

  const contactOptions = translation("CONTACT_OPTIONS", {
    returnObjects: true,
  });

  const [contact, setContact] = useState({
    first_name: "",
    last_name: "",
    email: "",
    subject: contactOptions[0],
    message: "",
  });

  const sendEmail = async () => {
    // If contact form is not filled, pop window alert //
    for (const [key, value] of Object.entries(contact)) {
      if (value === "" && key !== "message") {
        return window.alert(translation("FILL_MESSAGE"));
      }
    }

    setLoading(true);
    const mail = await apiSendMail(contact);
    if (mail.data !== "ok") {
      window.alert(translation("MESSAGE_FAILED"));
    } else {
      window.alert(translation("MESSAGE_SENT"));
      setContact({
        first_name: "",
        last_name: "",
        email: "",
        subject: contactOptions[0],
        message: "",
      });
    }
    setLoading(false);
  };

  return (
    <div className="contact-form">
      <div className="input-container flex media-700-flex-c">
        <CustomInput
          fieldName={translation("FIRST_NAME")}
          placeholder={translation("FIRST_NAME")}
          value={contact.first_name}
          onChange={(e) =>
            setContact({ ...contact, first_name: e.target.value })
          }
        />
        <CustomInput
          fieldName={translation("LAST_NAME")}
          placeholder={translation("LAST_NAME")}
          value={contact.last_name}
          onChange={(e) =>
            setContact({ ...contact, last_name: e.target.value })
          }
        />
      </div>
      <div className=" flex media-700-flex-c">
        <CustomInput
          fieldName={translation("EMAIL")}
          placeholder={translation("ENTER_EMAIL")}
          value={contact.email}
          onChange={(e) => setContact({ ...contact, email: e.target.value })}
        />
        <CustomInput
          fieldName={translation("SELECT_SUBJECT")}
          select={true}
          value={contact.subject}
          options={contactOptions}
          onChange={(e) => setContact({ ...contact, subject: e.target.value })}
        />
      </div>
      <div className="input-container flex media-700-flex-c">
        <CustomInput
          fieldName={translation("MESSAGE")}
          placeholder={translation("WRITE_MESSAGE")}
          value={contact.message}
          textarea={true}
          onChange={(e) => setContact({ ...contact, message: e.target.value })}
        />
      </div>
      <div>
        <CustomButton
          loading={loading}
          text={translation("SEND_MESSAGE")}
          custWidth={"50%"}
          onClick={() => sendEmail()}
        />
      </div>
    </div>
  );
}
