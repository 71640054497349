export function handleClick(e, type, idString, data, setData) {
  if (type === "radio") {
    const updatedPages = data.map((data) => {
      if (idString + data.id === e.currentTarget.id) {
        return { ...data, selected: true };
      } else {
        return { ...data, selected: false };
      }
    });
    return setData(updatedPages);
  }

  if (type === "checkbox") {
    const updatedData = data.map((data) => {
      if (idString + data.id === e.currentTarget.id) {
        return { ...data, selected: !data.selected };
      } else {
        return data;
      }
    });
    setData(updatedData);
  }
}

export function calculateEstimatedValue(data1, data2, data3) {
  let sum = 0;

  data1.forEach((element) => {
    if (element.selected === true) {
      return (sum += element.value);
    }
  });

  data2.forEach((element) => {
    if (element.selected === true) {
      return (sum += element.value);
    }
  });

  data3.forEach((element) => {
    if (element.selected === true) {
      return (sum += element.value);
    }
  });

  return sum;
}
