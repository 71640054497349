import React, { useEffect } from "react";
import { useState } from "react";
import "./CostCalculator.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../constants/data";

// Hooks //
import useHideScrollBar from "../../Hooks/useHideScrollBar";

// Context //
import {
  useCalculator,
  useCalculatorUpdate,
} from "../../Context/CalculatorContext";
import { useTranslationContext } from "../../Context/TranslationContext";

// Helpers //
import { handleClick } from "./CostCalculatorHelpers";
import { calculateEstimatedValue } from "./CostCalculatorHelpers";

export default function CostCalculator() {
  const navigate = useNavigate();
  const language = useTranslationContext();

  const { i18n } = useTranslation();
  const costCalculatorData = language("COST_CALCULATOR_DATA", {
    returnObjects: true,
  });

  const isOpen = useCalculator();
  const updateIsOpen = useCalculatorUpdate();

  // Disable page scrolling when calculator isOpen //
  useHideScrollBar(isOpen);

  // Navigate to contact, close calculator //
  const navigateFromCalculator = () => {
    navigate(navPaths[2]);
    updateIsOpen();
  };

  const [methodOptions, setMethodOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);

  // When i18n language changes, update states with current language //
  useEffect(() => {
    setMethodOptions(costCalculatorData.areas.methods.options);
    setContentOptions(costCalculatorData.areas.contents.options);
    setPageOptions(costCalculatorData.areas.pages.options);
  }, [i18n.language]);

  // Estimated value //
  const estimatedValue = calculateEstimatedValue(
    methodOptions,
    contentOptions,
    pageOptions
  );

  return (
    <div
      className={
        isOpen ? "cost-calculator cost-calculator-open" : "cost-calculator"
      }
    >
      <button id="calculator-closer" onClick={() => updateIsOpen()}>
        {costCalculatorData.close_button}
      </button>
      <div className="cost-calculator-container">
        <div className="calculator-header">
          <h1>{costCalculatorData.header}</h1>
          <h2>{costCalculatorData.header2}</h2>
        </div>
        <div className="calculator-options-container">
          <div className="calculator-methods options">
            <div className="option-header">
              {costCalculatorData.areas.methods.header}
            </div>
            <div className="calculator-options">
              {methodOptions.map((option, key) => {
                return (
                  <div
                    className="option-container"
                    id={`methods-${option.id}`}
                    key={key}
                    onClick={(e) =>
                      handleClick(
                        e,
                        "radio",
                        `methods-`,
                        methodOptions,
                        setMethodOptions
                      )
                    }
                    /* onClick={(e) => {
                      changeSelectedData(e, "methods");
                    }} */
                  >
                    <input
                      readOnly
                      type="radio"
                      name="methods"
                      checked={option.selected}
                    />
                    <p>{option.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="calculator-pages options">
            <div className="option-header">
              {costCalculatorData.areas.pages.header}
            </div>
            <div className="calculator-options">
              {pageOptions.map((option, key) => {
                return (
                  <div
                    className="option-container"
                    id={`pages-${option.id}`}
                    key={key}
                    onClick={(e) =>
                      handleClick(
                        e,
                        "radio",
                        `pages-`,
                        pageOptions,
                        setPageOptions
                      )
                    }
                  >
                    <input
                      readOnly
                      type="radio"
                      name="pages"
                      checked={option.selected}
                    />
                    <p>{option.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="calculator-contents options">
            <div className="option-header">
              {costCalculatorData.areas.contents.header}
            </div>
            <div className="calculator-options">
              {contentOptions.map((option, key) => {
                return (
                  <div
                    className="option-container"
                    id={`contents-${option.id}`}
                    key={key}
                    onClick={(e) =>
                      handleClick(
                        e,
                        "checkbox",
                        `contents-`,
                        contentOptions,
                        setContentOptions
                      )
                    }
                  >
                    <input
                      readOnly
                      type="checkbox"
                      id={option.text}
                      checked={option.selected}
                    />
                    <p>{option.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="estimation-container">
        <h3>{costCalculatorData.estimation.header}</h3>
        <div className="estimation-price">
          {estimatedValue} € + {language("ADDED_TAX")}
        </div>
        <button
          id="calculator-nav-button"
          onClick={() => {
            navigateFromCalculator();
          }}
        >
          {costCalculatorData.estimation.button}
        </button>
      </div>
    </div>
  );
}
