import React from "react";

import "./Services.css";

// Components //
import Seperator from "../../components/Seperator/Seperator";
import ServiceCard from "../../components/ServiceCard/ServiceCard";

// Context //
import { useTranslationContext } from "../../Context/TranslationContext";

export default function Services() {
  const translation = useTranslationContext();
  const servicePageCardData = translation("SERVICE_PAGE_CARD_DATA", {
    returnObjects: true,
  });

  return (
    <div className="services nav-padding">
      <div className="service-title">
        <h1>{translation("NAV_SERVICES")}</h1>
      </div>
      <div className="service-cards">
        <div className="service-cards-container">
          {servicePageCardData.map((data, index) => {
            return (
              <div className="card-wrapper" key={index}>
                <ServiceCard data={data} />
              </div>
            );
          })}
          <Seperator />
        </div>
      </div>
    </div>
  );
}
