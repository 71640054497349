import React from "react";
import "./HomeService.css";

// Components //
import ServiceCard from "../../../components/ServiceCard/ServiceCard";

// Context //
import { useTranslationContext } from "../../../Context/TranslationContext";

export default function HomeService() {
  const translation = useTranslationContext();
  const homeServiceData = translation("HOME_SERVICE_DATA", {
    returnObjects: true,
  });

  return (
    <div className="home-services flex">
      <div className="home-services-title">
        <h2> {homeServiceData.header}</h2>
      </div>
      <div className="home-services-cards">
        {homeServiceData.services.map((data, index) => {
          return (
            <ServiceCard
              key={index}
              data={data}
              optBackground={index % 2 ? true : false}
            />
          );
        })}
      </div>
    </div>
  );
}
