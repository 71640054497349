import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

// Components
import CustomButton from "../CustomButton/CustomButton";

// Utils
import { currentYear } from "../../utils/date";

// Data //
import { contactData } from "../../constants/data";
import { navPaths } from "../../constants/data";

// Context //
import { useTranslationContext } from "../../Context/TranslationContext";
import { useCalculatorUpdate } from "../../Context/CalculatorContext";

export default function Footer() {
  const translation = useTranslationContext();

  const toggleCalculator = useCalculatorUpdate();
  const navText = translation("NAV_TEXT", { returnObjects: true });
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-container flex media-1100-flex-c">
        <div className="footer-section flex">
          <div className="footer-logo footer-tm">
            {translation("FOOTER_HEADER")}
          </div>
          <p>{translation("FOOTER_PARAGRAPH")}</p>
        </div>
        <div className="footer-section flex">
          <div className="footer-tm">{translation("FOOTER_LINK_HEADER")}</div>
          <ul className="footer-links">
            <li onClick={() => navigate("/")}>{navText[0]}</li>
            <li onClick={() => navigate("/" + navPaths[1])}>{navText[1]}</li>
            <li onClick={() => toggleCalculator()}>
              {translation("PRICE_ESTIMATION")}
            </li>
          </ul>
          <CustomButton
            text={translation("NAV_CONTACT")}
            onClick={() => navigate("/" + navPaths[2])}
          />
        </div>
        <div className="footer-section flex">
          <div className="footer-tm">
            {translation("FOOTER_CONTACT_HEADER")}
          </div>
          <ul>
            <li>{contactData.email}</li>
            <li>{contactData.phone}</li>
            <li>{contactData.location}</li>
          </ul>
        </div>
      </div>
      <div className="footer-copyright">
        <p>
          © {currentYear()} <span>Softstack. </span>
          {translation("FOOTER_CP")}
        </p>
      </div>
    </div>
  );
}
