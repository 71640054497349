import React from "react";
import "./Map.css";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

export default function Map() {
  /* Prevent Google Maps from overriding current font style >> https://gist.github.com/boffey/8516b0091649161cb09e244d63076256 */
  var head = document.getElementsByTagName("head")[0];

  // Save the original method
  var insertBefore = head.insertBefore;

  // Replace it!
  head.insertBefore = function (newElement, referenceElement) {
    if (
      newElement.href &&
      newElement.href.indexOf(
        "https://fonts.googleapis.com/css?family=Roboto"
      ) === 0
    ) {
      return;
    }
    insertBefore.call(head, newElement, referenceElement);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const coordinates = { lat: 60.21815930870743, lng: 24.871235317791207 };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // ADD API KEY TO FINAL VERSION TROUGH SECRET //
    googleMapsApiKey: "AIzaSyAObE7EuzM-22gfyqOz8QShadKtoJOCBOM",
  });

  return isLoaded ? (
    <div className="map">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coordinates}
        zoom={13}
      >
        <Marker position={coordinates} title="SoftStack" />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}
